import React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Nenhuma página encontrada | Dia Supermercado</title>
        <meta name="description" content="Nenhuma página encontrada | Dia Supermercado" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <Layout>
        <h1 className="py-5 text-center">Nenhuma página encontrada</h1>
      </Layout>
    </>
  )
}

export default NotFoundPage
